<template>
  <div class="col-md-12">
    <div class="card card-info card-outline ">
      <div class="card-header pt-2 pb-2">
        <h3 class="card-title">
          <i class="fa fa-truck text-navy"></i> Reporte Trabajo
        </h3>
        <div class="card-tools">
          <button
            type="button"
            class="btn bg-frontera text-white"
            @click="addDetalle()"
            v-if="$parent.servicioNacional.estado == 1 || $parent.servicioNacional.estado == 2"
            :disabled="
              estado_detalle == 1 || 
              (!$parent.servicioNacional.fecha_fin1 || !$parent.servicioNacional.hora_fin1) ||
              fin_reportes
            "
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="card-body" v-if="csDetalles.length > 0 || estado_detalle == 1">
        <table class="table table-bordered table-striped table-hover table-sm p-2">
          <thead class="bg-dark">
            <tr>
              <th class="text-center">Hora Inicio</th>
              <th class="text-center">Hora Fin</th>
              <th class="text-center">Duración <small>(horas)</small></th>
              <th class="text-center">Tipo Tiempo</th>
              <th>Observaciones</th>
              <th v-if="$parent.servicioNacional.estado == 1 || $parent.servicioNacional.estado == 2">Acciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="(csDetalle, index) in csDetalles" :key="csDetalle.id">
              <td class="text-center">
                <div class="row" v-if="id_actualizar_detalle == index && estado_detalle == 2">
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="servicioDetalle.fecha_ini"
                      :class="
                        $v.servicioDetalle.fecha_ini.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      disabled
                      @input="validaHoras()"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="servicioDetalle.hora_ini"
                      placeholder="hora_ini"
                      label="Hora Inicial"
                      class="form-control form-control-sm"
                      :class="
                        $v.servicioDetalle.hora_ini.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      disabled
                      @input="validaHoras()"
                    />
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="csDetalle.fecha_ini"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="csDetalle.hora_ini"
                      placeholder="hora_ini"
                      label="Hora Inicial"
                      class="form-control form-control-sm"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      disabled
                    />
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div class="row" v-if="id_actualizar_detalle == index && estado_detalle == 2">
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="servicioDetalle.fecha_fin"
                      :class="
                        $v.servicioDetalle.fecha_fin.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaHoras(index)"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="servicioDetalle.hora_fin"
                      placeholder="Hora Fin"
                      label="hora_fin"
                      class="form-control form-control-sm"
                      :class="
                        $v.servicioDetalle.hora_fin.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      @input="validaHoras(index)"
                    />
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="csDetalle.fecha_fin"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="csDetalle.hora_fin"
                      placeholder="Hora Fin"
                      label="hora_fin"
                      class="form-control form-control-sm"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      disabled
                    />
                  </div>
                </div>
              </td>
              <td class="text-center text-nowrap">
                <div v-if="csDetalle.tiempo">
                  <div v-if="csDetalle.tiempo.cant_horas_mostrar">{{ csDetalle.tiempo.cant_horas_mostrar }}</div>
                  <!-- <div v-if="csDetalle.tiempo.years"><small>Años: {{ csDetalle.tiempo.years }}</small></div>
                  <div v-if="csDetalle.tiempo.meses"><small>Meses: {{ csDetalle.tiempo.meses }}</small></div>
                  <div v-if="csDetalle.tiempo.dias"><small>Días: {{ csDetalle.tiempo.dias }}</small></div>
                  <div v-if="csDetalle.tiempo.horas"><small>Horas: {{ csDetalle.tiempo.horas }}</small></div>
                  <div v-if="csDetalle.tiempo.minutos"><small>Minutos: {{ csDetalle.tiempo.minutos }}</small></div> -->
                </div>
                <div v-else>Sin establecer</div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_detalle == index && estado_detalle == 2">
                  <select
                    id="tipo_tiempo"
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.tipo_tiempo"
                    :class="
                      $v.servicioDetalle.tipo_tiempo.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_tiempo in listasForms.tipo_tiempos"
                      :key="tipo_tiempo.numeracion"
                      :value="tipo_tiempo.numeracion"
                    >
                      {{ tipo_tiempo.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <select
                    id="tipo_tiempo"
                    class="form-control form-control-sm"
                    v-model="csDetalle.tipo_tiempo"
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_tiempo in listasForms.tipo_tiempos"
                      :key="tipo_tiempo.numeracion"
                      :value="tipo_tiempo.numeracion"
                    >
                      {{ tipo_tiempo.descripcion }}
                    </option>
                  </select>
                </div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_detalle == index && estado_detalle == 2">
                  <input
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.observaciones"
                    :class="
                      $v.servicioDetalle.observaciones.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>
                  <input
                    class="form-control form-control-sm"
                    v-model="csDetalle.observaciones"
                    disabled
                  />
                </div>
              </td>
              <td v-if="$parent.servicioNacional.estado == 1 || $parent.servicioNacional.estado == 2">
                <div class="btn-group" v-if="estado_detalle != 2">
                  <button
                    type="button"
                    class="btn bg-navy"
                    :disabled="estado_detalle != -1"
                    @click="editDetalle(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    :disabled="estado_detalle != -1 || index != (csDetalles.length - 1)"
                    @click="destroyDetalle(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div class="btn-group" v-if="estado_detalle == 2 && id_actualizar_detalle == index">
                  <button
                    type="button"
                    class="btn bg-success"
                    v-show="!$v.servicioDetalle.$invalid"
                    @click="saveUpdateDetalle(index)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelDetalle(index)">
                    <i class="fa fa-ban"></i>
                  </button>
                </div>
                <div class="btn-group" v-if="estado_detalle == 2 && id_actualizar_detalle !== index">
                  <button
                    type="button"
                    class="btn bg-navy"
                    @click="editDetalle(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    :disabled=" index != (csDetalles.length - 1)"
                    @click="destroyDetalle(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr v-if="estado_detalle == 1">
              <td>
                <div class="row">
                  <div class="col-6">
                    <input
                      type="date"
                      id="fecha_ini"
                      class="form-control form-control-sm"
                      v-model="servicioDetalle.fecha_ini"
                      :class="
                        $v.servicioDetalle.fecha_ini.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      disabled
                      @input="validaHoras()"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="servicioDetalle.hora_ini"
                      placeholder="hora_ini"
                      label="Hora Inicial"
                      class="form-control form-control-sm"
                      :class="
                        $v.servicioDetalle.hora_ini.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      disabled
                      @input="validaHoras()"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="servicioDetalle.fecha_fin"
                      :class="
                        $v.servicioDetalle.fecha_fin.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaHoras()"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      v-model="servicioDetalle.hora_fin"
                      placeholder="Hora Fin"
                      label="hora_final"
                      class="form-control form-control-sm"
                      :class="
                        $v.servicioDetalle.hora_fin.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '23',
                      }"
                      @input="validaHoras()"
                    />
                  </div>
                </div>
              </td>
              <td class="text-center text-muted text-nowrap">
                <small>Por establecer</small>
              </td>
              <td>
                <select
                  id="tipo_tiempo"
                  class="form-control form-control-sm"
                  v-model="servicioDetalle.tipo_tiempo"
                  :class="
                    $v.servicioDetalle.tipo_tiempo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo in listasForms.tipo_tiempos"
                    :key="tipo_tiempo.numeracion"
                    :value="tipo_tiempo.numeracion"
                  >
                    {{ tipo_tiempo.descripcion }}
                  </option>
                </select>
              </td>
              <td>
                <input
                  class="form-control form-control-sm"
                  v-model="servicioDetalle.observaciones"
                  :class="
                    $v.servicioDetalle.observaciones.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </td>
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn bg-success"
                    v-if="!$v.servicioDetalle.$invalid"
                    @click="saveDetalle()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelDetalle()">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="datos_barra">
            <tr class="bg-light">
              <td colspan="6">
                <div class="progress">
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                    :style="`width: ${datos_barra.operativo.porcentaje != 'NaN' ? datos_barra.operativo.porcentaje : 0}%`"
                  >
                    <b>
                      {{ datos_barra.operativo.tiempo.years ? `Años: ${ datos_barra.operativo.tiempo.years }` : "" }}
                      {{ datos_barra.operativo.tiempo.meses ? `Meses: ${ datos_barra.operativo.tiempo.meses }` : "" }}
                      {{ datos_barra.operativo.tiempo.dias ? `Días: ${ datos_barra.operativo.tiempo.dias }` : "" }}
                      {{ datos_barra.operativo.tiempo.horas ? `Horas: ${ datos_barra.operativo.tiempo.horas }` : "" }}
                      {{ datos_barra.operativo.tiempo.minutos ? `Minutos: ${ datos_barra.operativo.tiempo.minutos }` : "" }}
                      ({{datos_barra.operativo.porcentaje != 'NaN' ? datos_barra.operativo.porcentaje : 0}}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                    :style="`width: ${datos_barra.disponible.porcentaje != 'NaN' ? datos_barra.disponible.porcentaje : 0}%`"
                  >
                    <b>
                      {{ datos_barra.disponible.tiempo.years ? `Años: ${ datos_barra.disponible.tiempo.years }` : "" }}
                      {{ datos_barra.disponible.tiempo.meses ? `Meses: ${ datos_barra.disponible.tiempo.meses }` : "" }}
                      {{ datos_barra.disponible.tiempo.dias ? `Días: ${ datos_barra.disponible.tiempo.dias }` : "" }}
                      {{ datos_barra.disponible.tiempo.horas ? `Horas: ${ datos_barra.disponible.tiempo.horas }` : "" }}
                      {{ datos_barra.disponible.tiempo.minutos ? `Minutos: ${ datos_barra.disponible.tiempo.minutos }` : "" }}
                      ({{datos_barra.disponible.porcentaje != 'NaN' ? datos_barra.disponible.porcentaje : 0}}%)
                    </b>
                  </div>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                    :style="`width: ${datos_barra.indisponible.porcentaje != 'NaN' ? datos_barra.indisponible.porcentaje : 0}%`"
                  >
                    <b>
                      {{ datos_barra.indisponible.tiempo.years ? `Años: ${ datos_barra.indisponible.tiempo.years }` : "" }}
                      {{ datos_barra.indisponible.tiempo.meses ? `Meses: ${ datos_barra.indisponible.tiempo.meses }` : "" }}
                      {{ datos_barra.indisponible.tiempo.dias ? `Días: ${ datos_barra.indisponible.tiempo.dias }` : "" }}
                      {{ datos_barra.indisponible.tiempo.horas ? `Horas: ${ datos_barra.indisponible.tiempo.horas }` : "" }}
                      {{ datos_barra.indisponible.tiempo.minutos ? `Minutos: ${ datos_barra.indisponible.tiempo.minutos }` : "" }}
                      ({{datos_barra.indisponible.porcentaje != 'NaN' ? datos_barra.indisponible.porcentaje : 0}}%)
                    </b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "CsServicioDetalle",
  data() {
    return {
      servicioDetalle: {
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_tiempo: null,
        observaciones: null,
      },
      listasForms: {
        tipo_tiempos: [],
      },
      csDetalles: [],
      estado_detalle: -1,
      id_actualizar_detalle: -1,
      fin_reportes: false,
      datos_barra: null,
    };
  },

  validations: {
    servicioDetalle: {
      fecha_ini: {
        required,
      },
      hora_ini: {
        required,
        timeFormat,
      },
      fecha_fin: {
        required,
      },
      hora_fin: {
        required,
        timeFormat,
      },
      tipo_tiempo: {
        required,
      },
      observaciones: {
        required,
      },
    },
  },

  methods: {
    async getIndex() {
      let me = this;
      me.$parent.cargando = true;
      await axios
        .get("/api/cs/serviciosNacionales/detalle", {
          params: {
            cs_servicio_nacional_id: me.$parent.servicioNacional.id,
          },
        })
        .then(async (response) => {
          me.csDetalles = await response.data;
          // Se ajustan los valores para el formulario 
          me.csDetalles.forEach(det => {
            const inicio_f = det.hora_ini.split(" ");
            const fin_f = det.hora_fin.split(" ");
            det.fecha_ini = inicio_f[0];
            det.hora_ini = inicio_f[1];
            det.fecha_fin = fin_f[0];
            det.hora_fin = fin_f[1];
            det.tiempo = this.getTiempo(`${det.fecha_ini} ${det.hora_ini}`, `${det.fecha_fin} ${det.hora_fin}`);
          });
          me.$parent.cargando = false;
        });
        await this.validaFinReportes();
    },

    getTiempo(hora_ini, hora_fin){
      if (hora_ini && hora_fin) {
        let inicio = moment(hora_ini);
        let fin = moment(hora_fin);
        let diferencia = fin.diff(inicio);

        // Se calcula la cantidad en horas según requerimiento de Frontera
        const cant_horas = diferencia / 1000 / 60 / 60;
        const cant_minutos = (cant_horas - parseInt(cant_horas)) * 10;

        return {
          years: moment.duration(diferencia).years(),
          meses: moment.duration(diferencia).months(),
          dias: moment.duration(diferencia).days(),
          horas: moment.duration(diferencia).hours(),
          minutos: moment.duration(diferencia).minutes(),
          cant_horas_mostrar: cant_minutos < 5 ? parseInt(cant_horas) : parseInt(cant_horas + 1),
        }
      }
      return null;
    },

    async addDetalle() {
      await this.limpiarDetalle();
      this.servicioDetalle.cs_servicio_nacional_id = this.$parent.servicioNacional.id;
      const inicio_servicio = this.getHoraInicio();
      this.servicioDetalle.fecha_ini = inicio_servicio.fecha;
      this.servicioDetalle.hora_ini = inicio_servicio.hora;
      this.servicioDetalle.fecha_fin = this.$parent.servicioNacional.fecha_fin1;
      this.servicioDetalle.hora_fin = this.$parent.servicioNacional.hora_fin1;
      this.estado_detalle = 1;
    },

    async saveDetalle() {
      let me = this;
      me.servicioDetalle.tiempo = this.getTiempo(`${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`, `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`);
      me.csDetalles.push(me.servicioDetalle);
      me.estado_detalle = -1;
      await this.validaFinReportes();
    },

    async saveDetalleTotal() {
      this.csDetalles.forEach(det => {
        det.hora_ini = `${det.fecha_ini} ${det.hora_ini}`;
        det.hora_fin = `${det.fecha_fin} ${det.hora_fin}`;
      });
      axios({
        method: "POST",
        url: "/api/cs/serviciosNacionales/detalle",
        data: {
          csDetalles: this.csDetalles,
          cs_servicio_nacional_id: this.$parent.servicioNacional.id,
        },
      })
        .then(async (response) => {
          await this.getIndex();
          this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async editDetalle(index) {
      this.id_actualizar_detalle = index;
      this.estado_detalle = 2;
      this.servicioDetalle = { ...this.csDetalles[index] };
      await this.validaFinReportes();
    },

    async saveUpdateDetalle(index) {
      this.servicioDetalle.tiempo = this.getTiempo(`${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`, `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`);
      this.csDetalles[index] = this.servicioDetalle;
      this.estado_detalle = -1;
      this.id_actualizar_detalle = -1;
      await this.validaFinReportes();
    },

    async limpiarDetalle() {
      this.servicioDetalle = {
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_tiempo: null,
        observaciones: null,
      };
    },

    async cancelDetalle(index = null) {
      this.estado_detalle = -1;

      // Se valida la fecha inicial del siguiente reporte
      if (index != null) {
        if (this.csDetalles.length - 1 > index) {
          const fecha_final_reporte = new Date(`${this.csDetalles[index].fecha_fin} ${this.csDetalles[index].hora_fin}`);
          this.csDetalles[index + 1].fecha_ini = moment(fecha_final_reporte.getTime() + (1000 * 60)).format("YYYY-MM-DD")
          this.csDetalles[index + 1].hora_ini = moment(fecha_final_reporte.getTime() + (1000 * 60)).format("HH:mm")
        }
        await this.validaFinReportes();
      }
    },

    async destroyDetalle(index) {
      this.$swal({
        title: "Quitar reporte de trabajo.",
        text: "Está seguro de quitar este reporte?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          this.csDetalles.splice(index, 1);
          this.validaFinReportes();
        }
      });
    },

    async getTipoTiempo() {
      await axios.get("/api/lista/144").then((response) => {
        this.listasForms.tipo_tiempos = response.data;
      });
    },

    getHoraInicio(){
      if (this.csDetalles.length > 0) {
        const fecha_final_ultimo_servicio = new Date(`${this.csDetalles[this.csDetalles.length-1].fecha_fin} ${this.csDetalles[this.csDetalles.length-1].hora_fin}`);
        return {
          fecha: moment(fecha_final_ultimo_servicio.getTime()).format("YYYY-MM-DD"),
          hora: moment(fecha_final_ultimo_servicio.getTime()).format("HH:mm"),
        };
      } else {
        return {
          fecha: this.$parent.servicioNacional.fecha_ini1,
          hora: this.$parent.servicioNacional.hora_ini1,
        };
      }
    },

    validaHoras(index = null) {
      if (!this.$v.servicioDetalle.hora_fin.$invalid) {
        const fecha_final_servicio = new Date(`${this.$parent.servicioNacional.fecha_fin1} ${this.$parent.servicioNacional.hora_fin1}`);
        const fecha_inicial_reporte = new Date(`${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`);
        const fecha_final_reporte = new Date(`${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`);

        if (fecha_final_reporte <= fecha_final_servicio) {
          if (fecha_inicial_reporte >= fecha_final_reporte) {
            this.servicioDetalle.hora_fin = null;
            this.$swal({
              icon: "error",
              title: `Hora inicial del reporte es superior a la hora final del reporte...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          } else if (index != null) {
            if (this.csDetalles.length - 1 > index) {
              const fecha_final_siguiente_reporte = new Date(`${this.csDetalles[index + 1].fecha_fin} ${this.csDetalles[index + 1].hora_fin}`);
              if (fecha_final_siguiente_reporte <= fecha_final_reporte) {
                this.servicioDetalle.hora_fin = null;
                this.$swal({
                  icon: "error",
                  title: `Hora final del reporte es superior a la hora final del siguiente reporte...`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              } else {
                this.csDetalles[index + 1].fecha_ini = moment(fecha_final_reporte.getTime()).format("YYYY-MM-DD")
                this.csDetalles[index + 1].hora_ini = moment(fecha_final_reporte.getTime()).format("HH:mm")
              }
            } 
          }
        } else {
          this.servicioDetalle.fecha_fin = this.$parent.servicioNacional.fecha_fin1;
          this.servicioDetalle.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `Hora final del reporte es superior a la hora final del servicio...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFinReportes(){
      this.fin_reportes = false;
      if (this.csDetalles.length > 0) {
        const fin_servicio = new Date(`${this.$parent.servicioNacional.fecha_fin1} ${this.$parent.servicioNacional.hora_fin1}`);
        const fin_reporte = new Date(`${this.csDetalles[this.csDetalles.length - 1].fecha_fin} ${this.csDetalles[this.csDetalles.length - 1].hora_fin}`);
        if (fin_reporte.getTime() == fin_servicio.getTime() || (fin_servicio.getTime() - fin_reporte.getTime()) < (1000 * 60) ) {
          this.fin_reportes = true;
        }

      }
      this.getDatosBarra();
    },

    getDatosBarra(){
       this.datos_barra = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0
        },
      };

      this.csDetalles.forEach(det => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.fecha_ini} ${det.hora_ini}`);
        const fin_reporte = new Date(`${det.fecha_fin} ${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();

        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra.indisponible.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      // Se calcula el tiempo del servicio
      const inicio_servicio = new Date(`${this.$parent.servicioNacional.fecha_ini1} ${this.$parent.servicioNacional.hora_ini1}`);
      const fin_servicio = new Date(`${this.$parent.servicioNacional.fecha_fin1} ${this.$parent.servicioNacional.hora_fin1}`);
      const tiempo_servicio = fin_servicio.getTime() - inicio_servicio.getTime();

      this.datos_barra.operativo.porcentaje = this.toFixed((this.datos_barra.operativo.cant * 100) / tiempo_servicio);
      this.datos_barra.disponible.porcentaje = this.toFixed((this.datos_barra.disponible.cant * 100) / tiempo_servicio);
      this.datos_barra.indisponible.porcentaje = this.toFixed((this.datos_barra.indisponible.cant * 100) / tiempo_servicio);

      // Se asignan los tiempos 
      this.datos_barra.operativo.tiempo = {
        years: moment.duration(this.datos_barra.operativo.cant).years(),
        meses: moment.duration(this.datos_barra.operativo.cant).months(),
        dias: moment.duration(this.datos_barra.operativo.cant).days(),
        horas: moment.duration(this.datos_barra.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra.operativo.cant).minutes(),
      };
      this.datos_barra.disponible.tiempo = {
        years: moment.duration(this.datos_barra.disponible.cant).years(),
        meses: moment.duration(this.datos_barra.disponible.cant).months(),
        dias: moment.duration(this.datos_barra.disponible.cant).days(),
        horas: moment.duration(this.datos_barra.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.disponible.cant).minutes(),
      };
      this.datos_barra.indisponible.tiempo = {
        years: moment.duration(this.datos_barra.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.indisponible.cant).minutes(),
      };
    },

    toFixed(num){
      return Number.parseFloat(num).toFixed(2)
    },

    back() {
      return this.$router.replace("/Cs/ServiciosNacional");
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getTipoTiempo();
  },
};
</script>
